import React from "react"
import RecommendationCard from "../recommendationCard/RecommendationCard"
import * as styles from "./RecommendationsListCard.module.css"

const RecommendationsListCard = ({ recommendations }) => {

  return (
    <div className={styles.recommendationsListCardContainer}>
      {recommendations.map(recommendation => {
        console.log(recommendation ,'recoom')
        return (
          <RecommendationCard
            key={recommendation.fields.slug}
            title={recommendation.title}
            slug={recommendation.fields.slug}
            status={recommendation.status}
          />
        )
      })}
    </div>
  )
}

export default RecommendationsListCard
