import React from "react"
import ResourceCard from "../resourceCard/ResourceCard"

const ResourcesListCard = ({ resources }) => {
  return (
    <div>
      {resources.map(resource => {
        return (
          <ResourceCard
            key={resource.title}
            title={resource.title}
            link={resource.link}
            source={resource.source}
            linkType={resource.linkType}
          />
        )
      })}
    </div>
  )
}

export default ResourcesListCard
