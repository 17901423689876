import React from "react"
import * as styles from "./ContentCard.module.css"

const ContentCard = ({ title, content }) => {
  return (
    <div className={styles.content}>
      <h3 className={styles.contentTitle}>{title}</h3>
      <h4 className={styles.contentBody}>{content}</h4>
    </div>
  )
}

export default ContentCard
