// extracted by mini-css-extract-plugin
export var headerContainer = "resource-page-module--headerContainer--xZhH3";
export var titleHeaderContainer = "resource-page-module--titleHeaderContainer--FO_-y";
export var title = "resource-page-module--title--FRiQg";
export var subTitle = "resource-page-module--subTitle--gZdDc";
export var mainContentBg = "resource-page-module--mainContentBg--3kTBT";
export var mainContentContainer = "resource-page-module--mainContentContainer--2wi8_";
export var tipsContainer = "resource-page-module--tipsContainer--3jlVA";
export var tipsTitle = "resource-page-module--tipsTitle--jFc64";
export var resourcesContainer = "resource-page-module--resourcesContainer--2L7GX";
export var resourcesContentContainer = "resource-page-module--resourcesContentContainer--ed33h";
export var relatedPagesBg = "resource-page-module--relatedPagesBg--1itTh";
export var relatedPagesContainer = "resource-page-module--relatedPagesContainer--3AoUv";
export var relatedPagesTitle = "resource-page-module--relatedPagesTitle--3IVFO";
export var footer = "resource-page-module--footer--3xUYn";