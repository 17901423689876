import React from "react"
import * as styles from "./RecommendationCard.module.css"
import { Link } from "gatsby"

const RecommendationCard = ({ title, slug, status }) => {
  if(status === "Coming Soon"){
    return (
      <Link className={styles.recomCardContainer}>
        <h3 className={styles.recomCardTitle}>{title}</h3>
        <div className={styles.contentBody}>
          Coming Soon
        </div>
      </Link>
    )

  }else{
    return (
      <Link to={`${slug}`} className={styles.recomCardContainer}>
        <h3 className={styles.recomCardTitle}>{title}</h3>
      </Link>
    )

  }

}

export default RecommendationCard
