import React from 'react'
import { graphql } from "gatsby"

import ContentCard from "../../components/contentCard/ContentCard"
import ResourcesListCard from "../../components/resourcesListCard/ResourcesListCard"
import RecommendationsListCard from "../../components/recommendationsListCard/RecommendationsListCard"
import CtaCard from "../../components/ctaCard/CtaCard"
import Footer from "../../components/footer/Footer"
import Layout from "../../components/layout"
import Nav from '../../components/Nav'
import * as styles from "./resource-page.module.css"

const ResourcePage = ({data}) => {
    console.log(data, 'rec 1')
    let topic = data.json
    let recommendations = data.allJson.nodes
    return (
      <Layout>
        <Nav />
        <div className={styles.headerContainer}>
          <div className={styles.titleHeaderContainer}>
            <h2 className={styles.title}>{topic.title}</h2>
            <h3 className={styles.subTitle}>{topic.subTitle}</h3>
          </div>
        </div>
        <div className={styles.mainContentBg}>
          <div className={styles.mainContentContainer}>
            <div className={styles.tipsContainer}>
              <h2 className={styles.tipsTitle}>Summary</h2>
              {topic.content.map(element => {
                return (
                  <ContentCard
                    key={element.title}
                    title={element.title}
                    content={element.content}
                  ></ContentCard>
                )
              })}
            </div>
            <div className={styles.resourcesContainer}>
              <h2 className={styles.resourcesTitle}>Resources</h2>
              <div className={styles.resourcesContentContainer}>
                <ResourcesListCard resources={topic.resources} />
              </div>
            </div>
          </div>
        </div>
  
        <div className={styles.relatedPagesBg}>
          <div className={styles.relatedPagesContainer}>
            <h2 className={styles.relatedPagesTitle}> Related Pages</h2>
            <RecommendationsListCard recommendations={recommendations} />
          </div>
        </div>
        <CtaCard />
        <Footer />
      </Layout>
    )
}

export default ResourcePage

export const query = graphql`
query($slug:String!, $category:String!){
  json(fields:{slug:{eq:$slug}}){
    title
    subTitle
    category
    subcategory
    templateType
    content{
      title
      content
    }
    resources{
      title
      link
      source
      linkType
    }
    fields{
      slug
    }
  }
  allJson(filter:{templateType:{eq:"resources"},category:{eq:$category},fields:{slug:{ne:$slug}}}){
    nodes{
      id
      title
      subcategory
      status
      fields{
        slug
      }
    }
  }
    
  }
`
