import React from "react"
import * as styles from "./ResourceCard.module.css"

const ResourceCard = ({ title, link, source, linkType }) => {
  return (
    <div className={styles.resourceContainer}>
      <a className={styles.resourceLink} href={link} target="_blank">
        <h2 className={styles.resourceTitle}>{title}</h2>
      </a>

      <h3 className={styles.resourceSubTitle}>{source}</h3>
      <h3 className={styles.resourceLinkType}>
        <em>{linkType}</em>
      </h3>
    </div>
  )
}

export default ResourceCard
